.testimonials {
    padding: 4rem 2rem;
    background: purple;
    color: white;
    text-align: center;
  
    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .swiper-slide {
      padding: 2rem;
      background: pink;
      border-radius: 8px;
      color: white;
  
      p {
        font-size: 1.1rem;
        line-height: 1.5;
      }
    }
  }
  