.portfolio {
  padding: 4rem 2rem;
  background-color: #fff;

  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;

    .portfolio-item {
      padding: 1.5rem;
      background: white;
      border-radius: 8px;
      text-align: center;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      button {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        background: blue;
        color: white;
        border: none;
        cursor: pointer;
      }
    }
  }
}
