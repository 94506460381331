.hero {
  background: linear-gradient(to right, red, blue);
  color: white;
  text-align: center;
  padding: 4rem 2rem;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  button {
    margin: 0 0.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    background: white;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: darken(orange, 10%);
    }
  }
}
