#philosophy {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
  
    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .philosophy-item {
      margin-bottom: 2rem;
  
      h3 {
        font-size: 1.5rem;
        color: blue;
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 1rem;
        color: pink;
      }
    }
  }
  