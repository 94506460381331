.services {
    padding: 4rem 2rem;
    background-color: #f1f1f1;
  
    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      list-style: none;
      padding: 0;
  
      li {
        background: grey;
        padding: 1rem;
        border-radius: 6px;
        flex: 1 1 200px;
        text-align: center;
      }
    }
  }
  